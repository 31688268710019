import {
  AdvertisementSystem,
  BlockPubArticleHeader,
  BlockPubHeaderMenu,
  BlockPubNavSqueeze,
  BlockPubPromo,
  ExternalDisplay,
  FacebookColorScheme,
  FeedPublishStatus,
  HeaderCustomCtaType,
  IframeAspectRatio,
  LeaderboardPosition,
  Paywall,
  PlanAdSettings,
  StripeAccountType,
  WritingRuleType,
} from '@models/types'

export const MAX_NUMBER_OF_ARTICLES_AUTHOR = 60
export const MAX_NUMBER_OF_ARTICLES_TAG = 60
export const MAX_NUMBER_OF_ARTICLES_SECTION = 60
export const MAX_NUMBER_OF_ARTICLES_LATEST_STORIES = 60
export const TOP_STORY_MAX_NB_OF_PRI_ARTICLES = 6
export const DEFAULT_PLAN_TOGGLE_TO_YEAR = true
export const DEFAULT_AD_SYSTEM: AdvertisementSystem = 'custom'
export const DEFAULT_ARTICLE_ACCESS: Paywall = 'Public'
export const DEFAULT_EXTERNAL_DISPLAY: ExternalDisplay = 'topStory'
export const DEFAULT_ARTICLE_TYPE: BlockPubArticleHeader['type'] = 'default'
export const DEFAULT_IS_IFRAME: BlockPubArticleHeader['isIframe'] = false
export const DEFAULT_HEADER_MENU_LOGO_SIZE: NonNullable<BlockPubHeaderMenu['logoSize']> = 'small'
export const DEFAULT_NAV_SQUEEZE_LOGO_SIZE: NonNullable<BlockPubNavSqueeze['logoSize']> = 'small'
export const PUB_HEADER_AD_HERO_DEFAULT_VISIBILITY = false
export const DEFAULT_IFRAME_EXTERNAL_DISPLAY: IframeAspectRatio = 'landscape'
export const DEFAULT_GALLERY_EXTERNAL_DISPLAY: IframeAspectRatio = 'landscape'
export const PUBLICATION_INTERNAL_PAGE_SLUGS = {
  home: '/',
  sitemap: '/sitemap/',
  subscribe: '/subscribe/',
  signIn: '/sign-in/',
  account: '/account/',
  payments: '/payments/',
  thankYou: '/thank-you/',
  terms: '/terms/',
  privacyPolicy: '/privacy-policy/',
  latestStories: '/latest-stories/',
  search: '/search/',
  events: '/events/',
  contactUs: '/contact-us/',
  notFound: '/404/',
  newsletters: '/newsletters/',
  tags: '/tags',
  writers: '/writers',
  sections: '/sections',
  stories: '/stories',
  allTags: '/tags/',
  allWriters: '/writers/',
  allSections: '/sections/',
  gift: '/gift/',
  giftRedeem: '/gift-redeem/',
}
export const DEFAULT_NUMBER_FACEBOOK_COMMENTS_SHOWN = 5
export const DEFAULT_FACEBOOK_COLOR_SCHEME: FacebookColorScheme = 'light'
export const DEFAULT_SHOW_STORY_COMMENTS = false
export const DEFAULT_LEADERBOARD_AD_POSITION: LeaderboardPosition = 'bottom'
export const MAIN_HEADER_INJECTED_SCRIPT_CONTAINER_ID = 'main-header-injected-script-container'
export const MAIN_FOOTER_INJECTED_SCRIPT_CONTAINER_ID = 'main-footer-injected-script-container'
export const NEWSLETTER_INJECTED_SCRIPT_CONTAINER_ID = 'newsletter-injected-script-container'
export const STORY_INJECTED_SCRIPT_CONTAINER_ID = 'story-injected-script-container'
export const HOME_PAGE_INJECTED_SCRIPT_CONTAINER_ID = 'home-page-injected-script-container'
export const ARTICLE_RELATED_ARTICLES_INJECTED_SCRIPT_CONTAINER_ID =
  'article-related-articles-injected-script-container'
export const DEFAULT_PROMO_CUSTOM_TITLE = 'Unlock Unparalleled Access'
export const DEFAULT_GIFT_PROMO_CUSTOM_TITLE = ''
export const DEFAULT_PROMO_CUSTOM_POPUP_TIME_IN_SECONDS = 30
export const DEFAULT_GIFT_PROMO_CUSTOM_POPUP_TIME_IN_SECONDS = 30
export const DEFAULT_PROMO_TYPE: BlockPubPromo['modalContentType'] = 'promo'
export const DEFAULT_PROMO_MODAL_COOLDOWN_FOR_NON_SUBSCRIBED_USER = 30
export const DEFAULT_GIFT_MODAL_COOLDOWN_FOR_SUBSCRIBED_USER = 30
export const DEFAULT_GIFT_MODAL_COOLDOWN_FOR_NON_SUBSCRIBED_USER = 30
export const DEFAULT_FEED_PUBLISH_STATUS: FeedPublishStatus = 'draft'
export const DEFAULT_FEED_PAYWALL: Paywall = 'Public'
export const DEFAULT_FEED_IS_ACTIVE: boolean = false
export const COLLECTION_SIZE_LIMIT = 500
export const DEFAULT_PLAN_AD_SETTINGS: PlanAdSettings = 'AllAds'
export const DEFAULT_WORKSPACE_WRITING_RULE_TYPE: WritingRuleType = 'Body'
export const DEFAULT_WORKSPACE_WRITING_RULE_ACTIVE: boolean = true
export const DEFAULT_STRIPE_ACCOUNT_TYPE: StripeAccountType = 'publication'
export const DEFAULT_HEADER_CUSTOM_CTA_TYPE: HeaderCustomCtaType = 'LINK'
export const DEFAULT_SHOW_CUSTOM_DONATION_SUBSCRIPTION_PAGE: boolean = false
